/* -----------------------------------------------------------------------
 * #EMBED
 * ----------------------------------------------------------------------- */

/**
 * @todo Add description
 */

.c-embed {
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    flex-basis: 100%;
  }
}

  .c-embed__dialog {
    width: calc(100% - 2 * var(--layout-margin));
    max-width: var(--layout-narrow);
    margin-right: auto;
    margin-left: auto;
    padding-top: var(--spacing-xl);
    padding-bottom: var(--spacing-xl);
  }

  .c-embed__title {
    margin-bottom: var(--spacing-md);
    line-height: 1.2;
  }

  .c-embed__action {
    margin-top: var(--spacing-md);
  }
