/* -----------------------------------------------------------------------
 * #LIST
 * ----------------------------------------------------------------------- */

/**
 * @todo: Add description
 */

.o-property-list {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: var(--spacing-md);
}
