/* -----------------------------------------------------------------------
 * #QUOTES
 * ----------------------------------------------------------------------- */

/**
 * Block Quotes
 *
 * Enforce hanging punctuation on block quotes. Use the `hanging-punctuation`
 * property when browser support increases (currently only supported by Safari
 * 10+).
 */

blockquote {
  position: relative;

  & > p:first-child::before {
    content: open-quote;
    position: absolute;
    right: 100%;
  }

  & > p:last-child::after {
    content: close-quote;
    position: absolute;
  }

  & > p:not(:last-child) {
    margin-bottom: var(--spacing-lg);
  }
}



/**
 * Quotes
 *
 * Define the quotation marks to be used for a selected range of languages.
 */

q,
blockquote {

  &:lang(en) {
    quotes: "“" "”" "‘" "’";
  }

  &:lang(de) {
    quotes: "„" "“" "‚" "‘";
  }
}
