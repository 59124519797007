/* -----------------------------------------------------------------------
 * #LAYOUT
 * ----------------------------------------------------------------------- */

/**
 * Global Layout Variables
 *
 * A collection of project-wide layout variables. These variables influence how
 * various contents are arranged on the page.
 */

:root {

  /* Insets
   * ---------------------------------------------------------------------- */

   --layout-margin:     1.5rem;

  /* Gutters
  * ---------------------------------------------------------------------- */

  --layout-gutter:     1.5rem;

  /* Measures
   * ---------------------------------------------------------------------- */

  --layout-narrow:     42rem;
  --layout-wide:       58rem;
}
