/* -----------------------------------------------------------------------
 * #REEL
 * ----------------------------------------------------------------------- */

/**
 * @todo: Add description
 */

.o-reel {
  display: flex;
  height: auto;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-color: var(--color-secondary) var(--color-canvas);

  & > * {
    flex: 0 0 auto;
  }

  & > img {
    flex-basis: auto;
    width: auto;
    height: 100%;
  }

  & > * + * {
    margin-left: var(--layout-gutter);
  }

  &::-webkit-scrollbar {
    height: 1rem;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--color-canvas);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-secondary);
    background-image: linear-gradient(var(--color-canvas) 0, var(--color-canvas) 0.25rem, var(--color-secondary) 0.25rem, var(--color-secondary) 0.75rem, var(--color-canvas) 0.75rem);
  }
}
