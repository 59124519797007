/* -----------------------------------------------------------------------
 * #FRAME
 * ----------------------------------------------------------------------- */

/**
 * @todo: Add description
 */

.o-frame {
  --frame-numerator: 2;
  --frame-denominator: 3;

  position: relative;
  padding-bottom: calc(100% * var(--frame-numerator) / var(--frame-denominator));
}

  .o-frame > * {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .o-frame > img,
  .o-frame > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }



/**
 * @todo: Add description
 */

.o-frame--16\:3 {
  --frame-numerator: 3;
  --frame-denominator: 16;
}

.o-frame--16\:9 {
  --frame-numerator: 9;
  --frame-denominator: 16;
}
