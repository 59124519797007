/* -----------------------------------------------------------------------
 * #SWITCHER
 * ----------------------------------------------------------------------- */

/**
 * @todo: Add description
 */

.o-switcher {
  --switcher-gutter: var(--spacing-lg);
  --switcher-width: calc(100% - 2 * var(--switcher-gutter));
  --switcher-multiplier: 9999;

  display: flex;
  flex-wrap: wrap;
  align-items: var(--switcher-align, stretch);
  margin-top: calc(-1 * var(--switcher-gutter));
  margin-left: calc(-1 * var(--switcher-gutter));
}

  .o-switcher > * {
    flex-grow: 1;
    flex-basis: calc(var(--switcher-multiplier) * (var(--switcher-breakpoint, 40rem) - var(--switcher-width)));
    padding-top: var(--switcher-gutter);
    padding-left: var(--switcher-gutter);
  }

  .o-switcher > :nth-last-child(n+3),
  .o-switcher > :nth-last-child(n+3) ~ * {
    flex-basis: 100%;
  }



/**
 * @todo: Add description
 */

.o-switcher--top {
  --switcher-align: flex-start;
}

.o-switcher--center {
  --switcher-align: center;
}

.o-switcher--bottom {
  --switcher-align: flex-end;
}



/**
 * @todo: Add description
 */

.o-switcher--reverse {
  flex-direction: row-reverse;
}
