/* -----------------------------------------------------------------------
 * #BLOCKQUOTE
 * ----------------------------------------------------------------------- */

/**
 * @todo: Add description
 */

.c-blockquote {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: var(--spacing-lg);
  border-radius: 0.75em;
  color: var(--color-text);
  background-color: var(--color-canvas-tint);

  @nest .u-bg-tint & {
    filter: brightness(0.95);
  }

  @media (--lg-up) {
    @nest .s-prose & {
      margin-right: calc(-1 * var(--layout-gutter));
      margin-left: calc(-1 * var(--layout-gutter));
    }
  }
}

  .c-blockquote__content {
    hyphens: auto;
  }

  .c-blockquote__author {
    display: grid;
    column-gap: var(--spacing-md);
    align-items: center;
    margin-top: auto;
    padding-top: var(--spacing-lg);
    grid-template: "figure content" 1fr "figure footer" auto / fit-content(64px) 1fr;

    @nest .c-blockquote--left & {
      align-self: flex-start;
    }

    @nest .c-blockquote--right & {
      align-self: flex-end;
    }
  }

  .c-blockquote__author-initials,
  .c-blockquote__author-photo {
    grid-area: figure;
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    box-shadow: 0 0 0 2px var(--color-neutral-dark);
  }

  .c-blockquote__author-initials {
    width: 64px;
    line-height: 64px;
    text-align: center;
    background-color: var(--color-canvas-tint);
  }

  .c-blockquote__author-name {
    font-weight: 500;
    font-size: 0.875em;
    grid-area: content;
  }

  .c-blockquote__author-meta {
    color: var(--color-text-muted);
    font-size: 0.75em;
    letter-spacing: 0.0625em;
    text-transform: uppercase;
  }



/**
 * @todo: Add description
 */

.c-blockquote--left,
.c-blockquote--right {
  position: relative;
  margin-top: var(--spacing-lg);
  margin-bottom: var(--spacing-lg);

  &::before,
  &::after {
    content: "";
    position: absolute;
    bottom: 100%;
    width: 2.5em;
    height: 2.5em;
    background-color: inherit;
  }

  &::after {
    background-color: var(--color-canvas);
  }
}

.c-blockquote--left {
  border-top-left-radius: 0;

  &::before,
  &::after {
    left: 0;
  }

  &::after {
    border-bottom-left-radius: 100%;
  }
}

.c-blockquote--right {
  border-top-right-radius: 0;

  &::before,
  &::after {
    right: 0;
  }

  &::after {
    border-bottom-right-radius: 100%;
  }
}
