/* -----------------------------------------------------------------------
 * #PROCESS
 * ----------------------------------------------------------------------- */

/**
 * @todo: Add description
 */

.c-process {
  --process-marker-color: var(--color-primary);
  --process-item-gutter: var(--spacing-xl);
  --process-item-width: 20em;

  display: flex;
  list-style: none;
  counter-reset: process;

  &::after {
    content: "";
    padding-left: var(--process-item-gutter);
  }
}

  .c-process__step {
    counter-increment: process;
    position: relative;
    flex-basis: var(--process-item-width);
    margin-top: var(--spacing-lg);
    margin-bottom: var(--spacing-lg);
    margin-left: var(--process-item-gutter);

    &::before,
    &::after {
      content: "";
      display: block;
      border-radius: 0.25rem;
      color: var(--color-white);
      font-size: 5rem;
      background-color: var(--process-marker-color);
    }

    &::before {
      content: counter(process) ". ";
      width: 2em;
      margin-bottom: 0.5em;
      padding: 0.25em 0.25em 0.125em;
      border-top-right-radius: 0;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      box-shadow: var(--shadow-1);
    }

    &::after {
      position: absolute;
      left: 1.25em;
      bottom: 100%;
      width: 0.75em;
      height: 0.125em;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .c-process__pointer {
    position: absolute;
    top: 3em;
    left: 8em;
    right: 0;
    z-index: var(--layer-above);
  }



/**
 * @todo: Add description
 */

.c-process--reel {
  margin-bottom: calc(-1 * var(--banner-spacing, 0));
}

  .c-process--reel > .c-process__step {
    margin-bottom: calc(var(--banner-spacing, 0) + var(--spacing-lg));
  }
