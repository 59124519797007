/* -----------------------------------------------------------------------
 * #CHECKBOX
 * ----------------------------------------------------------------------- */

/**
 * @todo: Add description
 */

.c-checkbox {
  position: relative;
  display: block;
  cursor: pointer;
}

  .c-checkbox__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .c-checkbox__label {
    display: block;
    padding-left: 1.5em;

    &::before {
      content: "";
      position: absolute;
      top: 0.25em;
      left: 0;
      display: block;
      width: 1em;
      height: 1em;
      border-radius: 0.25em;
      color: var(--color-neutral-light);
      line-height: 1em;
      background-color: var(--color-neutral-light);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 50% 50%;
      pointer-events: none;
      user-select: none;

      @nest .c-checkbox__input:focus ~ & {
        outline: 0.125rem solid var(--color-outline);
        outline-offset: 0.125rem;
        -moz-outline-radius: 0.25rem;
      }

      @nest .c-checkbox__input:checked ~ & {
        color: var(--color-white);
        background-color: var(--color-secondary);
        background-image: url("../images/tick.svg");
      }

      @nest .c-checkbox__input:active ~ & {
        color: var(--color-white);
        background-color: var(--color-secondary-light);
      }
    }
  }
