/* -----------------------------------------------------------------------
 * #TABLES
 * ----------------------------------------------------------------------- */

/**
 * Tables
 *
 * 1. Ensure tables fill available space.
 * 2. Remove spacing between the borders of table cells.
 */

table {
  width: 100%; /* [1] */
  border-collapse: collapse; /* [2] */
  border-spacing: 0; /* [2] */
}
