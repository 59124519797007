/* -----------------------------------------------------------------------
 * #DOCUMENT
 * ----------------------------------------------------------------------- */

/* stylelint-disable length-zero-no-unit */

/**
 * Document Root
 *
 * Establishes the vertical baseline grid and customizes the default `font-size`
 * and `line-height` used.
 *
 * @see {@link https://www.zachleat.com/web/css-tricks-web-fonts/}
 *
 * 1. Fixes Safari bug with viewport units in calc().
 * 2. Force scrollbars to always be visible to avoid page jumps.
 * 3. Use fluid typography and provide a fallback for older browsers. Font size
 *    increases from 1rem to 1.375rem for viewports between 448px (28rem) and
 *    1248px (78rem): font_size = 1 + (1.375 - 1) * ((100vw - 28) / (78 - 28))
 * 4. Enable smooth scrolling between on page links.
 */

html {
  min-height: 0vw; /* [1] */
  overflow-y: scroll; /* [2] */
  color: var(--color-text);
  font-family: var(--font-family-sans);
  font-size: var(--base-font-size-md);
  font-size: clamp(var(--base-font-size-sm), calc(1rem + (0.75 * (1vw - 0.28rem))), var(--base-font-size-lg)); /* [3] */
  line-height: var(--base-line-height);
  background-color: var(--color-canvas);
  scroll-behavior: smooth; /* [4] */
}
