/* -----------------------------------------------------------------------
 * #ICONS
 * ----------------------------------------------------------------------- */

/**
 * Icon Object
 *
 * The icon object normalizes the appearance of svg icons. SVG icons are
 * generally embedded via a generated icon sprite. Individual icons in the
 * sprite sheet can be referenced by the unique identifier of the symbol.
 *
 * @example markup
 *   <svg class="o-icon o-icon--mail" width="24" height="24" aria-hidden="true">
 *     <use xlink:href="/assets/images/icons.svg#icon-mail"></use>
 *   </svg>
 */

.o-icon {
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  font-size: 1em;
  line-height: 1;
  fill: currentColor;
  stroke: none;
}



/**
 * Icon Positions
 *
 * Some icons are accompanied by descriptive text. To increase the space between
 * text and icon the following classes can be used.
 *
 * @example markup
 *   <button class="c-button">
 *     <svg class="o-icon o-icon--left" width="24" height="24" aria-hidden="true">
 *       <use xlink:href="/assets/images/icons.svg#icon-mail"></use>
 *     </svg>
 *     Submit
 *   </button>
 */

.o-icon--left {
  margin-right: 0.25em;
}

.o-icon--right {
  margin-left: 0.25em;
}



/**
 * Icon Sizes
 *
 * Since an SVG sprite sheet is used for icons, the individual icons can be
 * scaled without any loss of quality. The following classes specify typical
 * sizes that are used throughout the document.
 *
 * @example markup
 *   <svg class="o-icon o-icon--lg" width="24" height="24" aria-hidden="true">
 *     <use xlink:href="/assets/images/icons.svg#icon-mail"></use>
 *   </svg>
 */

.o-icon--xs {
  font-size: 0.75em;
}

.o-icon--sm {
  font-size: 0.875em;
}

.o-icon--md {
  font-size: 1.25em;
}

.o-icon--lg {
  font-size: 1.5em;
}

.o-icon--ml {
  font-size: 1.875em;
}

.o-icon--xl {
  font-size: 2.25em;
}
