/* -----------------------------------------------------------------------
 * #ANIMATIONS
 * ----------------------------------------------------------------------- */

/**
 * Animation utilities
 *
 * A group of reusable animation utilities.
 */

.u-animate {
  animation-duration: var(--animation-duration, 0.75s);
  animation-fill-mode: both;

  &.u-infinite {
    animation-iteration-count: infinite;
  }

  &.u-slower {
    animation-duration: calc(3 * var(--animation-duration));
  }

  &.u-slow {
    animation-duration: calc(2 * var(--animation-duration));
  }

  &.u-fast {
    animation-duration: calc(0.8 * var(--animation-duration));
  }

  &.u-faster {
    animation-duration: calc(0.5 * var(--animation-duration));
  }
}



/**
 * Sliding animation
 *
 * Slide an element in and out.
 */

@keyframes back-in-down {
  0% {
    opacity: 0;
    transform: translate(var(--start-position-x, 0%), var(--start-position-y, -100%)) scale(var(--start-scale, 0.85));
  }

  80% {
    opacity: 0.7;
    transform: translate(0%, 0%) scale(var(--start-scale, 0.85));
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.a-back-in-down {
  animation-name: back-in-down;
}

@keyframes back-out-down {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: 0.7;
    transform: translate(0%, 0%) scale(var(--end-scale, 0.85));
  }

  100% {
    opacity: 0;
    transform: translate(var(--end-position-x, 0%), var(--end-position-y, 100%)) scale(var(--end-scale, 0.85));
  }
}

.a-back-out-down {
  animation-name: back-out-down;
}



/**
 * Stroke animation
 *
 * Animate the stroke of an svg path.
 */

@keyframes stroke {
  to { stroke-dashoffset: 0; }
}

.a-stroke {
  animation-name: stroke;
}

/**
 * User Preference: Reduced motion
 *
 * @see {@link https://github.com/mozdevs/cssremedy/issues/11 What's best…}
 *
 * 1. Immediately jump any animation to the end point.
 * 2. Remove any transitions.
 */

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    background-attachment: initial !important;
    transition-delay: 0s !important;
    transition-duration: 0s !important;
    animation-duration: 1ms !important;
    animation-delay: -1ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
  }
}
