/* -----------------------------------------------------------------------
 * #MISC
 * ----------------------------------------------------------------------- */

 :root {

  /* Constants
   * --------------------------------------------------------------------- */

  --phi:                   1.618033;
  --square-root-of-two:    1.414213;
}
