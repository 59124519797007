/* -----------------------------------------------------------------------
 * #COOKIECONSENT
 * ----------------------------------------------------------------------- */

/**
 * @todo Add description
 */

.cc-window {
  font-family: var(--font-family-sans) !important;
  box-shadow: var(--shadow-4) !important;

  &.cc-floating {
    @media (--sm-only) {
      left: 1em !important;
      right: 1em !important;
      max-width: none !important;
    }
  }
}

.cc-window.cc-invisible {
  display: none;
}

.cc-center {
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  @media (--sm-only) {
    top: auto;
    bottom: 1rem;
    left: 1em !important;
    right: 1em !important;
    max-width: none !important;
    margin-right: 0;
    transform: translate(0, 0);
    }
}
