/* -----------------------------------------------------------------------
 * #DEPTHS
 * ----------------------------------------------------------------------- */

/**
 * Elevation System
 *
 * A set of utility classes that implement an elevation system that can be used
 * to increase the relative distance between two elements along the z-axis.
 *
 * @example markup
 *   <header class="c-header / u-depth">…</header>
 */

.u-depth-1 {
  box-shadow: var(--shadow-1);
}

.u-depth-2 {
  box-shadow: var(--shadow-2);
}

.u-depth-3 {
  box-shadow: var(--shadow-3);
}
