/* -----------------------------------------------------------------------
 * #HEADER
 * ----------------------------------------------------------------------- */

/**
 * @todo: Add description
 */

.c-header {
  --header-border-color: var(--color-primary);
  --header-border-thickness: 1.5em;
  --header-logo-width: 169px;
  --header-logo-height: 76px;

  position: relative;
  z-index: var(--layer-header);
  display: block;
  padding-top: var(--spacing-md);
  padding-bottom: var(--spacing-md);
  border-bottom: var(--header-border-thickness) solid var(--header-border-color);
  color: var(--color-text);
  background-color: var(--color-white);
}

  .c-header__inner,
  .c-header__nav,
  .c-header__brand {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .c-header__brand {
    max-width: var(--header-logo-width);
    margin-right: 1.5em;

    @media (--sm-only) {
      max-width: calc(0.6 * var(--header-logo-width));
    }
  }
