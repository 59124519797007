/* -----------------------------------------------------------------------
 * #SIZES
 * ----------------------------------------------------------------------- */

/**
 * Font Sizes
 *
 * Make all font sizes available as utility classes. These classes allow to
 * style heading elements independently from their semantic meaning.
 *
 * @example markup
 *   <h2 class="u-size-xl">…</h2>
 */

.u-size-xl {
  font-size: var(--font-size-xl);
}

.u-size-lg {
  font-size: var(--font-size-lg);
}

.u-size-md {
  font-size: var(--font-size-md);
}

.u-size-sm {
  font-size: var(--font-size-sm);
}

.u-size-xs {
  font-size: var(--font-size-xs);
}
