/* -----------------------------------------------------------------------
 * #SPACINGS
 * ----------------------------------------------------------------------- */

/**
 * Element Spacing
 *
 * A collection of helper classes to increase the horizontal distance between
 * elements.
 *
 * @example markup
 *   <footer class="c-footer / u-gap-above">…</footer>
 */

.u-gap-above,
.u-gap-above-md {
  margin-top: var(--spacing-md);
}

.u-gap-above-xs {
  margin-top: var(--spacing-xs);
}

.u-gap-above-sm {
  margin-top: var(--spacing-sm);
}

.u-gap-above-lg {
  margin-top: var(--spacing-lg);
}

.u-gap-above-xl {
  margin-top: var(--spacing-xl);
}

.u-gapless-above {
  margin-top: 0;
}

.u-gap-below,
.u-gap-below-md {
  margin-bottom: var(--spacing-md);
}

.u-gap-below-xs {
  margin-bottom: var(--spacing-xs);
}

.u-gap-below-sm {
  margin-bottom: var(--spacing-sm);
}

.u-gap-below-lg {
  margin-bottom: var(--spacing-lg);
}

.u-gap-below-xl {
  margin-bottom: var(--spacing-xl);
}

.u-gapless-below {
  margin-bottom: 0;
}


.u-pad-both-md {
  padding: var(--spacing-md) 0;
}

.u-pad-both-lg {
  padding: var(--spacing-lg) 0;
}
