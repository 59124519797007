/* -----------------------------------------------------------------------
 * #HAMBURGER
 * ----------------------------------------------------------------------- */

/**
 * @todo: Add description
 */

.c-hamburger {
  --hamburger-thickness: 0.1em;
  --hamburger-spacing: 0.15em;
  --hamburger-radius: 0.1em;

  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 0.6em;
  font-size: 1.5em;
}

  .c-hamburger__inner {
    bottom: 0;
    margin-top: calc(-0.5 * var(--hamburger-thickness));
    transition: transform 0.13s 0.13s ease-in-cubic;

    &,
    &::before,
    &::after {
      position: absolute;
      display: block;
      width: 100%;
      height: var(--hamburger-thickness);
      border-radius: var(--hamburger-radius);
      background-color: currentColor;
    }

    &::before,
    &::after {
      content: "";
    }

    &::before {
      top: calc(-1 * (var(--hamburger-thickness) + var(--hamburger-spacing)));
      transition:
        top 0.12s 0.2s ease-out-cubic,
        transform 0.13s ease-in-cubic;
    }

    &::after {
      top: calc(-1 * (2 * var(--hamburger-thickness) + 2 * var(--hamburger-spacing)));
      transition:
        top 0.2s 0.2s ease-out-cubic,
        opacity 0.1s linear;
    }

    @nest .is-active & {
      transform: translate3d(0, calc(-1 * (var(--hamburger-thickness) + var(--hamburger-spacing))), 0) rotate(-45deg);
      transition: transform 0.13s 0.22s ease-out-cubic;
    }

    @nest .is-active &::before {
      top: 0;
      transform: rotate(-90deg);
      transition:
        top 0.1s 0.16s ease-in-cubic,
        transform 0.13s 0.25s ease-out-cubic;
    }

    @nest .is-active &::after {
      top: 0;
      opacity: 0;
      transition:
        top 0.1s 0.16s ease-in-cubic,
        opacity 0.1s 0.22s linear;
    }
  }



/**
 * @todo: Add description
 */

.c-hamburger--sm {
  font-size: 1em;
}

.c-hamburger--lg {
  font-size: 2.5em;
}



/**
 * @todo: Add description
 */

.c-hamburger--left {
  margin-right: 0.25em;
}

.c-hamburger--right {
  margin-left: 0.25em;
}
