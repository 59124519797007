/* -----------------------------------------------------------------------
 * #CONTAINER
 * ----------------------------------------------------------------------- */

/**
 * @todo: Add description
 */

.o-container {
  width: calc(100% - 2 * var(--layout-margin));
  margin-right: auto;
  margin-left: auto;
}

  .o-container--narrow {
    max-width: var(--layout-narrow);
  }

  .o-container--wide {
    max-width: var(--layout-wide);
  }
