/* -----------------------------------------------------------------------
 * #REBOOT
 * ----------------------------------------------------------------------- */

/* Document
 * ----------------------------------------------------------------------- */

/**
 * Remove default margin.
 */

body,
h1,
h2,
h3,
h4,
blockquote,
p,
pre,
figure,
figcaption,
fieldset,
legend,
ol,
ul,
dl,
dd,
li {
  margin: 0;
}

/**
 * Remove default padding.
 */

ol,
ul {
  padding: 0;
}

/**
 * Don’t mess with the line height. Imitate subscript/superscript characters
 * provided by IBM Plex.
 */

sub,
sup {
  position: relative;
  vertical-align: baseline;
  font-weight: 700;
  font-size: 0.5em;
  line-height: 0;
}

sup {
  top: -0.625em;
}

/**
 * Customize the appearance of text selections.
 */

::selection {
  color: var(--color-text);
  background-color: var(--color-selection);
}


/* Embedded Elements
 * ----------------------------------------------------------------------- */

/**
 * Make elements responsive by default, and remove vertical spacing from inline
 * elements.
 */

img,
svg,
canvas,
audio,
video {
  vertical-align: middle;
  max-width: 100%;
}

/**
 * Maintain intrinsic aspect ratios when `max-width` is applied.
 */

img,
svg,
canvas,
video {
  height: auto;
}

/**
 * Prefer full width over the default of 300px.
 */

audio {
  width: 100%;
}

/**
 * Hide embedded JSON.
 */

script[type="application/json"] {
  display: none;
}

/* Forms
 * ----------------------------------------------------------------------- */

/**
 * Correct the inheritance and scaling of font size in all browsers.
 */

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

/**
 * Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 * on fieldsets.
 */

fieldset {
  min-width: 0;
  border: 0;
}
