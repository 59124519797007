/* -----------------------------------------------------------------------
 * #TESTIMONIAL
 * ----------------------------------------------------------------------- */

/**
 * @todo: Add description
 */

.c-testimonial {
  display: flex;
  flex-direction: column-reverse;
}

  .c-testimonial__content {
    display: flex;
    flex-direction: row-reverse;
    color: var(--color-text-inverse);
    background-color: var(--color-black);
    box-shadow: var(--shadow-3);
  }

  .c-testimonial__aside {
    margin-bottom: var(--spacing-md);
    text-align: center;
    background: url("../images/thought-bubble.svg") no-repeat center top / contain;
  }

  .c-testimonial__quote {
    flex-basis: 62%;
    align-self: center;
    padding: var(--spacing-lg);
  }

    .c-testimonial__quote-excerpt {
      quotes: "„" "“" "‚" "‘";
      position: relative;
      font-weight: 500;
      font-size: var(--font-size-sm);
      line-height: 1.2;

      &::before,
      &::after {
        position: absolute;
      }

      &::before {
        content: open-quote;
        right: 100%;
      }

      &::after {
        content: close-quote;
      }
    }

    .c-testimonial__quote-cite {
      max-width: 100%;
      margin-top: var(--spacing-sm);
      overflow: hidden;
      font-size: var(--font-size-xs);
      text-overflow: ellipsis;
      white-space: nowrap;
    }

  .c-testimonial__figure {
    flex-basis: 38%;

    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
