/* -----------------------------------------------------------------------
 * #ALIGNMENT
 * ----------------------------------------------------------------------- */

/**
 * Alignment and more :) utilities
 *
 * A group of reusable align utilities.
 */

 .u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-text-left {
  text-align: left;
}

.u-text-center {
  text-align: center;
}

.u-text-right {
  text-align: right;
}
