/* -----------------------------------------------------------------------
 * #SHADOWS
 * ----------------------------------------------------------------------- */

 :root {
  --shadow-1: 0 2px 2px 0 hsl(0 0% 0% / 0.04), 0 3px 1px -2px hsl(0 0% 0% / 0.03), 0 1px 5px 0 hsl(0 0% 0% / 0.05);
  --shadow-2: 0 4px 5px 0 hsl(0 0% 0% / 0.04), 0 1px 10px 0 hsl(0 0% 0% / 0.03), 0 2px 4px -1px hsl(0 0% 0% / 0.05);
  --shadow-3: 0 8px 17px 2px hsl(0 0% 0% / 0.04), 0 3px 14px 2px hsl(0 0% 0% / 0.03), 0 5px 5px -3px hsl(0 0% 0% / 0.05);
  --shadow-4:
    0 8px 17px 12px hsl(0 0% 0% / 0.04),
    0 3px 14px 12px hsl(0 0% 0% / 0.03),
    0 5px 5px -13px hsl(0 0% 0% / 0.05);
}
