/* -----------------------------------------------------------------------
 * #TYPOGRAPHY
 * ----------------------------------------------------------------------- */

/**
 * Typography Settings
 *
 * A collection of variables controlling the typographic rules applied to the
 * entire document with all its components.
 *
 * @see {@link http://spencermortensen.com/articles/typographic-scale/}
 */

:root {

  /* Document
   * --------------------------------------------------------------------- */

  --base-font-size-sm:     1rem;
  --base-font-size-md:     1.125rem;
  --base-font-size-lg:     1.375rem;
  --base-line-height:      1.75;

  /* Families
   * --------------------------------------------------------------------- */

  --font-family-sans:      "Geomanist", system-ui;
  --font-family-serif:     "Charter", "Palatino", "Palatino Linotype", serif;

  /* Sizes (⨍ᵢ=⨍₀⋅𝑟^(𝑖÷𝑛))
   * --------------------------------------------------------------------- */

  --font-size-xl:          3rem;
  --font-size-lg:          2.25rem;
  --font-size-md:          1.75rem;
  --font-size-sm:          1.35rem;
  --font-size-xs:          0.875em;
}
