/* -----------------------------------------------------------------------
 * #BOXSIZING
 * ----------------------------------------------------------------------- */

/**
 * Make CSS Layouts easier by opting for a different box model than the default
 * content-box.
 *
 * @see {@link https://css-tricks.com/?p=175473}
 */

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
