/* -----------------------------------------------------------------------
 * #BUTTON
 * ----------------------------------------------------------------------- */

/**
 * @todo: Add description
 */

.c-button {
  --button-padding: 0.25rem;
  --button-border: 0.125rem;
  --button-height: calc(var(--base-line-height) * 1rem + var(--button-padding) * 2 + var(--button-border) * 2);
  --button-text-color: var(--color-text-inverse);
  --button-background-color: var(--color-secondary);

  display: inline-block;
  vertical-align: middle;
  padding: var(--button-padding) 1.25rem;
  border: var(--button-border) solid var(--button-background-color);
  border-radius: 0.25rem;
  outline: 0;
  color: var(--button-text-color);
  font: inherit;
  text-transform: none;
  background-color: var(--button-background-color);

  &:not(:disabled) {
    cursor: pointer;
  }

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    --button-background-color: var(--color-secondary-light);
  }

  &:not(:disabled):active {
    --button-background-color: var(--color-secondary-dark);

    transform: translateY(1px);
  }

  &:not(:disabled):focus-visible {
    outline: var(--button-border) solid var(--color-outline);
    outline-offset: 0.125rem;
    -moz-outline-radius: 0.25em;
  }
}



/**
 * @todo: Add description
 */

.c-button--light {
  --button-text-color: var(--color-text);
  --button-background-color: var(--color-canvas);

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    --button-background-color: var(--color-neutral-lighter);
  }

  &:not(:disabled):active {
    --button-background-color: var(--color-neutral-light);
  }
}

.c-button--dark {
  --button-text-color: var(--color-text-inverse);
  --button-background-color: var(--color-canvas-inverse);

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    --button-background-color: var(--color-neutral-dark);
  }

  &:not(:disabled):active {
    --button-background-color: var(--color-neutral-darker);
  }
}



/**
 * @todo: Add description
 */

.c-button--outline {
  --button-text-color: var(--color-secondary);
  --button-background-color: currentColor;

  background-color: transparent;

  &:not(:disabled):hover,
  &:not(:disabled):focus,
  &:not(:disabled):active {
    --button-background-color: currentColor;
  }

  &.c-button--light {
    --button-text-color: var(--color-canvas);
  }

  &.c-button--dark {
    --button-text-color: var(--color-canvas-inverse);
  }
}



/**
 * @todo: Add description
 */

.c-button--more {
  --button-arrow-size: calc(var(--button-height) / var(--square-root-of-two));

  position: relative;
  padding-right: calc(var(--button-arrow-size) + 0.5rem);

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0.5rem;
    width: var(--button-arrow-size);
    height: var(--button-arrow-size);
    border: var(--button-border) solid transparent;
    border-top-color: currentColor;
    border-right-color: currentColor;
    transform: translate(-15%, -50%) rotate(45deg);
    transition: transform 0.2s ease-in-out-quad;
  }

  &:not(:disabled):hover::after,
  &:not(:disabled):focus::after {
    transform: translate(-5%, -50%) rotate(45deg);
  }
}



/**
 * @todo: Add description
 */

.c-cta {
  position: fixed;
  right: var(--layout-margin);
  bottom: var(--layout-margin);
  z-index: var(--layer-dialog);

  @nest .js & {
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.4s 0.5s linear, transform 0.4s 0.5s ease-out-quart;
  }

  @nest .js &.is-active {
    opacity: 1;
    transform: scale(1);
    transition-delay: 0s, 0s;
  }
}

  .c-cta__avatar {
    position: relative;
    z-index: var(--layer-above);
    display: block;
    width: 6em;
    height: 6em;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: var(--shadow-3);
  }

  .c-cta__message {
    position: absolute;
    top: 50%;
    right: 100%;
    min-width: 16em;
    margin-right: 0.5em;
    padding: 0.5em;
    color: var(--color-text-inverse);
    font-size: var(--font-size-xs);
    line-height: 1.25;
    background-color: var(--color-secondary);
    box-shadow: var(--shadow-3);
    transform: translate3d(0, -50%, 0);

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 100%;
      border: 0.375em solid transparent;
      border-top-color: var(--color-secondary);
      border-left-color: var(--color-secondary);
    }

    @nest .js & {
      opacity: 0;
      transform: translate3d(20%, -50%, 0);
      transition: opacity 0.4s 0s linear, transform 0.4s 0s ease-in-out-quart;
    }

    @nest .js .c-cta.is-active & {
      opacity: 1;
      transform: translate3d(0, -50%, 0);
      transition-delay: 1s, 1s;
    }
  }

  .c-cta__action {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: var(--layer-above);
    display: block;
    padding: 0.125em;
    border-radius: 0.25em;
    color: var(--color-text);
    background-color: var(--color-canvas);
    box-shadow: var(--depth-1);
  }
