/* -----------------------------------------------------------------------
 * #GOOGLE
 * ----------------------------------------------------------------------- */

/**
 * @todo Add description
 */

.gm-style .gm-style-iw {

  & h2,
  & h3 {
    margin-bottom: var(--spacing-sm);
    font-weight: 700;
    font-size: 1.25em;
    line-height: 1.2;
  }

  & p:not(:last-child) {
    margin-bottom: var(--spacing-sm);
  }
}
