/* -----------------------------------------------------------------------
 * #COLORS
 * ----------------------------------------------------------------------- */

/**
 * Text Colors
 *
 * Makes all colors of the current theme available as utility classes to
 * customize the text color of an element.
 *
 * @example markup
 *   <footer class="c-banner / u-text-invert u-bg-invert">…</footer>
 */

.u-text-primary {
  color: var(--color-primary);
}

.u-text-secondary {
  color: var(--color-secondary);
}

.u-text-default {
  color: var(--color-text);
}

.u-text-invert {
  color: var(--color-text-inverse);
}

.u-text-muted {
  color: var(--color-text-muted);
}



/**
 * Background Colors
 *
 * Makes all colors of the current theme available as utility classes to
 * customize the background color of an element.
 *
 * @example markup
 *   <section class="c-banner / u-bg-tint">…</section>
 */

.u-bg-primary {
  background-color: var(--color-primary);
}

.u-bg-secondary {
  background-color: var(--color-secondary);
}

.u-bg-default {
  background-color: var(--color-canvas);
}

.u-bg-invert {
  background-color: var(--color-canvas-inverse);
}

.u-bg-tint {
  background-color: var(--color-canvas-tint);
}
