/* -----------------------------------------------------------------------
 * #FOOTER
 * ----------------------------------------------------------------------- */

/**
 * @todo: Add description
 */

.c-footer {
  --footer-inset: var(--spacing-md);
  --footer-gutter: var(--spacing-lg);

  padding-top: var(--footer-inset);
  padding-bottom: var(--footer-inset);
}

  .c-footer__section {
    padding-top: var(--footer-gutter);
    padding-bottom: var(--footer-gutter);

    &:not(:first-child) {
      border-top: 1px solid var(--color-border-inverse);
    }

    @media (--lg-up) {
      &:last-child > .c-footer__inner {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
      }
    }
  }

  .c-footer__colophon {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-start;
    margin-top: -0.25em;
    margin-left: -0.25em;
    font-style: normal;

    & > * {
      margin-top: 0.25em;
      margin-left: 0.25em;
    }

    @media (--md-up) {
      & > :last-child {
        margin-left: auto;
      }
    }

    @media (--lg-up) {
      & {
        margin-left: auto;
      }

      & > :last-child {
        margin-left: var(--footer-gutter);
      }
    }
  }

  .c-footer__nav {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    margin-left: -0.75em;
  }

  .c-footer__nav-link {
    display: inline-block;
    vertical-align: baseline;
    margin-left: 0.75em;

    &.u-last {
      order: 999;
      margin-left: auto;
    }

    &:focus-visible {
      outline: 0.125rem solid var(--color-outline);
      outline-offset: 0.125rem;
      -moz-outline-radius: 0.25em;
    }

    &:focus:not(:focus-visible) {
      outline: 0;
    }
  }

  .c-footer__widget-area {
    padding-top: var(--footer-gutter);
    padding-bottom: var(--footer-gutter);
  }
