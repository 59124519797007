/* -----------------------------------------------------------------------
 * #MEDIA
 * ----------------------------------------------------------------------- */

/**
 * @todo: Add description
 */

.o-media {
  display: flex;
  align-items: flex-start;
}

  .o-media__figure {
    margin-right: var(--layout-gutter);
  }

  .o-media__body {
    flex: 1;
  }



/**
 * @todo: Add description
 */

.o-media--reverse {
  flex-direction: row-reverse;

  & > .o-media__figure {
    margin-right: 0;
    margin-left: var(--layout-gutter);
  }
}



/**
 * @todo: Add description
 */

.o-media--top {
  align-items: flex-start;
}

.o-media--center {
  align-items: center;
  justify-content: center;
}

.o-media--bottom {
  align-items: flex-end;
}



/**
 * @todo: Add description
 */


.o-media--responsive {

  &.o-media--three {

    & img {
      max-width: 33%;
    }
  }
}


@media (--sm-only) {
  .o-media--responsive {
    flex-direction: column;
    align-items: flex-start;

    & > .o-media__figure {
      margin-right: 0;
      margin-bottom: var(--layout-gutter);
    }

    &.o-media--reverse {
      flex-direction: column-reverse;

      & > .o-media__figure {
        margin-top: var(--layout-gutter);
        margin-bottom: 0;
        margin-left: 0;
      }
    }

    &.o-media--three {

      & img {
        max-width: 100%;
      }
    }
  }
}
