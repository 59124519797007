/* -----------------------------------------------------------------------
 * #LINK
 * ----------------------------------------------------------------------- */

/**
 * @todo Add description
 */

.c-link {
  color: var(--color-secondary);
}
