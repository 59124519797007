/* -----------------------------------------------------------------------
 * #HEADING
 * ----------------------------------------------------------------------- */

/**
 * Stylized Headings
 *
 * Styles of headings are derived from the browser styles. In order to customize
 * the appearance of headings in the document you have to `opt-in` by using the
 * corresponding classes.
 */

.c-heading {
  font-weight: 700;
}

  .c-heading--quote {
    quotes: "„" "“" "‚" "‘";
    position: relative;

    &::before,
    &::after {
      position: absolute;
    }

    &::before {
      content: open-quote;
      right: 100%;
    }

    &::after {
      content: close-quote;
    }
  }

.c-subheading {
  font-weight: 400;
}

.c-heading,
.c-subheading {
  margin-bottom: var(--spacing-md);
  line-height: 1.2;
  hyphens: manual;
  column-span: all;
  page-break-after: avoid;
  text-rendering: optimizeLegibility;
  scroll-margin-top: 10vh;

  &:target {
    color: var(--color-accent);
  }
}

  .c-heading + .c-subheading {
    margin-top: calc(-1 * var(--spacing-md));
    margin-bottom: var(--spacing-md);
  }
