/* -----------------------------------------------------------------------
 * #PROSE
 * ----------------------------------------------------------------------- */

/**
 * Prose/Markdown Content
 *
 * Styling elements generated by a Markdown parser. In order to minimize the
 * necessary markup in markdown files the elements generated by a markdown
 * parser can be styled using a scoping class.
 *
 * @see {@link https://csswizardry.com/2015/03/more-transparent-ui-code-with-namespaces/#scope-namespaces-s-}
 */

.s-prose {
  --prose-text-color: var(--color-text);
  --prose-marker-color: var(--color-primary);

  & > :not([class]):not(:last-child) {
    margin-bottom: var(--spacing-md);
  }

  & ul:not([class]),
  & ol:not([class]) {
    list-style-position: outside;
    padding-left: var(--spacing-md);
  }

  & ul:not([class]) {
    list-style-type: disc;
  }

  & li:not([class]) {
    break-inside: avoid;
    margin-bottom: var(--spacing-sm);
    padding-left: var(--spacing-sm);

    &::marker {
      color: var(--prose-marker-color);
      font-weight: 700;
    }
  }

  & ul:not([class]) ul,
  & ul:not([class]) ol,
  & ol:not([class]) ul,
  & ol:not([class]) ol {
    margin-top: var(--spacing-sm);
  }

  @nest .u-bg-primary & {
    --prose-marker-color: var(--color-secondary);
  }
}
