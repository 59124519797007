/* -----------------------------------------------------------------------
 * #SPACINGS
 * ----------------------------------------------------------------------- */

/**
 * Global Spacing Units
 *
 * Spacing units help to maintain a consistent vertical rhythm. Theses spacing
 * values are determined based on your project’s global baseline grid.
 *
 * @see {@link https://medium.com/eightshapes-llc/space-in-design-systems-188bcbae0d62}
 */

:root {
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 2rem;
  --spacing-xl: 4rem;
}
