/* -----------------------------------------------------------------------
 * #CAROUSEL
 * ----------------------------------------------------------------------- */

/**
 * @todo: Add description
 */

.c-carousel {
  display: block;
  max-width: 100%;
}

  .c-carousel__frame {
    display: flex;
    flex-direction: column-reverse;
  }

  .c-carousel__controls,
  .c-carousel__slides {
    position: relative;
    list-style: none;
  }

  .c-carousel__controls {
    display: flex;
    justify-content: center;
    padding-top: var(--spacing-md);
    font-size: var(--font-size-lg);

    & > :first-child {
      margin-right: 1em;
    }

    & > :not(:first-child) {
      margin-left: 0.25em;
    }
  }

  .c-carousel__slide {
    position: relative;
    z-index: var(--layer-above);

    @nest .is-ready &:not(.is-active) {
      position: absolute;
      top: 0;
      left: 0;
      z-index: var(--layer-below);
      visibility: hidden;
    }

    @nest .is-ready &.a-enter {
      visibility: visible;
      animation: back-in-down var(--carousel-animation-duration, 0.75s) 0.05s ease-in-quart both;
    }

    @nest .is-ready &.a-leave {
      visibility: visible;
      animation: back-out-down var(--carousel-animation-duration, 0.75s) ease-out-quart both;
    }

    @media (--md-down) {
      @nest .is-ready &.a-enter {
        --start-position-x: -100%;
        --start-position-y: 0%;
      }
    }

    @media (--md-down) {
      @nest .is-ready &.a-leave {
        --end-position-x: 100%;
        --end-position-y: 0%;
      }
    }
  }

  .c-carousel__toggle-symbol,
  .c-carousel__dot-symbol {
    display: inline-block;
    vertical-align: middle;
    width: 1em;
    height: 1em;
  }

  .c-carousel__toggle-symbol {
    fill: currentColor;
    stroke: none;
  }

  .c-carousel__dot-symbol {
    stroke-width: 4%;
    fill: none;
    stroke: currentColor;

    @nest .is-active & {
      fill: currentColor;
      stroke: none;
    }
  }

  .c-carousel__toggle-button,
  .c-carousel__button {
    display: inline-block;
    vertical-align: middle;
    padding: 0;
    border: 0;
    font: inherit;
    line-height: 1;
    background-color: transparent;
    cursor: pointer;
    transition: transform 0.2s, outline 0.2s;

    &:hover,
    &:focus {
      transform: scale(1.1);
    }

    &:active {
      opacity: 1;
      transform: scale(1);
    }
  }

  .c-carousel__progress-outline,
  .c-carousel__progress {
    fill: none;
    stroke: currentColor;
  }

  .c-carousel__progress {
    animation: stroke var(--carousel-interval, 7s) linear infinite paused;

    &.is-active {
      animation-play-state: running;
    }
  }

  .c-carousel__play {
    display: none;

    @nest .is-paused & {
      display: block;
    }
  }

  .c-carousel__pause {
    display: none;

    @nest .is-cycling & {
      display: block;
    }
  }

  .c-carousel__instructions {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    margin-top: var(--spacing-lg);
    opacity: 0.68;
    color: var(--color-text-muted);
    text-align: center;
  }

  .c-carousel__instruction {
    display: block;
    font-size: 0.75rem;

    @nest
      .touch &,
      .c-carousel__frame:focus:hover ~ .c-carousel__instructions .c-carousel__instruction--hocus ~ &,
      .c-carousel__frame:focus-within:hover ~ .c-carousel__instructions .c-carousel__instruction--hocus ~ & {
      display: none !important;
    }
  }

  .c-carousel__instruction--hover {
    display: none;

    @nest
    .c-carousel__frame:hover ~ .c-carousel__instructions &,
    .c-carousel__frame:focus:hover ~ .c-carousel__instructions & {
      display: block;
    }
  }

  .c-carousel__instruction--focus {
    display: none;

    @nest
      .c-carousel__frame:focus ~ .c-carousel__instructions &,
      .c-carousel__frame:focus-within ~ .c-carousel__instructions & {
      display: block;
    }
  }

  .c-carousel__instruction--hocus {
    display: none;

    @nest
      .c-carousel__frame:focus:hover ~ .c-carousel__instructions &,
      .c-carousel__frame:focus-within:hover ~ .c-carousel__instructions & {
      display: block;
    }
  }

  .c-carousel__instruction--touch {
    display: none;

    @nest .touch & {
      display: block !important;
    }
  }
