/* -----------------------------------------------------------------------
 * #GRID
 * ----------------------------------------------------------------------- */

/**
 * @todo: Add description
 */

.o-grid {
  display: grid;
  list-style: none;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, minmax(var(--grid-width, 16rem), 1fr));
  grid-gap: var(--grid-gutter, var(--layout-gutter));
}



/**
 * @todo: Add description
 */

.o-grid--sm {
  --grid-gutter: calc(0.5 * var(--layout-gutter));
}

.o-grid--lg {
  --grid-gutter: calc(2 * var(--layout-gutter));
  --grid-width: 12rem;
}

.o-grid--flush {
  --grid-gutter: 0;
}
