/* -----------------------------------------------------------------------
 * #ACCESSIBILITY
 * ----------------------------------------------------------------------- */

/**
 * Assistive Text
 *
 * These classes hide elements visually, but keep them accessible for screen-
 * readers and other assistive technologies.
 *
 * @see {@link https://snook.ca/s/992 Hiding Content for Accessibility}
 */

.u-sr-only,
.u-skip-link {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  white-space: nowrap;
}

  .u-sr-only.u-focusable:active,
  .u-sr-only.u-focusable:focus,
  .u-skip-link:active,
  .u-skip-link:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    clip-path: none;
    white-space: normal;
  }

  .u-skip-link:focus {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: var(--layer-trump);
    padding: var(--spacing-sm) var(--spacing-md);
    border-bottom-left-radius: 0.25em;
    border-bottom-right-radius: 0.25em;
    outline: 0;
    color: var(--color-text-inverse);
    background-color: var(--color-secondary);
    transform: translateX(-50%);
  }



/**
 * Focus Indicator.
 *
 * Adds a focus indicator to an element once it receives focus. Should only be
 * used on focusable elements like a, button or input.
 */

.u-focusable {
  outline: none;

  &:focus-visible {
    outline: 0.125rem solid var(--color-outline);
    outline-offset: 0.25rem;
    -moz-outline-radius: 0.25rem;
  }
}
