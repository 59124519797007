/* -----------------------------------------------------------------------
 * #LINKS
 * ----------------------------------------------------------------------- */

/**
 * Anchor Links
 *
 * Normalize the appearance of document links and force them to inherit the
 * text color of the surrounding elements.
 *
 * @see `.c-link` for details on how to apply styles to a link
 */

a {
  color: inherit;
  text-decoration: none;
}
