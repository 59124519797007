/* -----------------------------------------------------------------------
 * #LAYERS
 * ----------------------------------------------------------------------- */

:root {
  --layer-trump:    100;
  --layer-dialog:    30;
  --layer-header:    20;
  --layer-menu:      10;
  --layer-above:      2;
  --layer-default:    1;
  --layer-below:      0;
}
