/* -----------------------------------------------------------------------
 * #NAVIGATION
 * ----------------------------------------------------------------------- */

/**
 * @todo: Add description
 */

.c-nav {
  --nav-gutter: 1.5em;
}

.c-nav__menu-toggle,
.c-nav__dropdown-toggle {
  padding: 0;
  border: 0;
  color: inherit;
  font: inherit;
  text-transform: none;
  background-color: transparent;
  cursor: pointer;
}

.c-nav__menu-toggle {
  padding: 0.25em 1em;
  border: 0.125em solid currentColor;
  border-radius: 0.25em;
}

.c-nav__menu-toggle,
.c-nav__dropdown-toggle,
.c-nav__link {
  display: inline-block;
  vertical-align: baseline;
  outline: 0;
  white-space: nowrap;

  &:focus-visible {
    outline: 0.125rem solid var(--color-outline);
    outline-offset: 0.25rem;
    -moz-outline-radius: 0.25rem;
  }
}

.c-nav__item {
  position: relative;
  display: block;
}

.c-nav__item--active,
.c-nav__dropdown-toggle--active,
.c-nav__dropdown-item--active {
  font-weight: 700;
}



/**
 * @todo: Add description
 */

@media (--md-down) {
  .c-nav__menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: var(--layer-menu);
    border-top: var(--header-border-thickness, 1.5em) solid var(--header-border-color, var(--color-primary));
    color: var(--color-text-inverse);
    background-color: var(--color-canvas-inverse);
    box-shadow: var(--shadow-3);
  }

  .c-nav__link,
  .c-nav__dropdown-toggle {
    padding-top: var(--spacing-sm);
    padding-bottom: var(--spacing-sm);
  }

  .c-nav__item {
    padding-right: var(--layout-margin);
    padding-left: var(--layout-margin);

    &:not(:first-child) {
      border-top: 1px solid hsl(0 0% 100% / 0.05);
    }
  }

  .c-nav__dropdown-menu {
    list-style: none;
    padding-bottom: var(--spacing-sm);
  }

  .c-nav__icon .c-nav__link {
    padding-top: var(--spacing-xs);
  }

  .c-nav__icon .c-nav__item {
    padding-right: var(--spacing-sm);
    padding-left: 0;
  }
}



/**
 * @todo: Add description
 */

@media (--lg-up) {
  .c-nav__menu-toggle {
    display: none;
  }

  .c-nav__menu {
    display: flex;
    list-style: none;
    min-height: 100%;
    margin-left: calc(-1 * var(--nav-gutter));
  }

  .c-nav__item {
    display: inline-block;
    vertical-align: baseline;
    margin-left: var(--nav-gutter);
    padding-top: var(--spacing-md);
    padding-bottom: var(--spacing-md);
  }

  .c-nav__item--active {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      display: block;
      width: 100%;
      height: 0.2em;
      margin-top: var(--spacing-md);
      border-radius: 0.25em;
      background-color: currentColor;
    }
  }

  .c-nav__dropdown-menu {
    position: absolute;
    top: 100%;
    left: 50%;
    z-index: var(--layer-dropdown);
    list-style: none;
    min-width: 10em;
    padding: 0.25em 0.5em;
    border: 1px solid var(--color-border);
    border-radius: 0.25em;
    color: var(--color-text);
    background-color: var(--color-canvas);
    transform: translateX(-50%);
  }

  .c-nav__icon {
    display: none;
  }
}
