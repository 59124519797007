/* -----------------------------------------------------------------------
 * #TEXT
 * ----------------------------------------------------------------------- */

/**
 * Font Weights
 *
 * A set of utility classes that can be used to change to font weight of an
 * element without affecting the semantic meaning.
 */

.u-text-normal {
  font-weight: 400;
}

.u-text-book {
  font-weight: 500;
}

.u-text-bold {
  font-weight: 700;
}


/**
 * Text Columns
 *
 * Spread the given text content into multiple columns.
 */

.u-text-columns {
  columns: 40ch auto;
  column-gap: var(--layout-gutter);
}



/**
 * Text Underlines
 *
 * A utility class that uses an svg image as text underline. The svg image is
 * typically generated using JavaScript to create the illusion of a rough
 * annotation.
 *
 * @example markup
 *   <span class="with-text-underline">
 *     Hello World
 *     <svg><path d="…" /></svg>
 *   </span>
 */

.with-text-underline {
  position: relative;
  z-index: 0;

  & > svg {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: auto;
    margin-top: -0.3em;
    pointer-events: none;
    overflow: visible;
  }

  & path {
    transition: stroke-dashoffset 0.5s ease-in-out-quad;
  }
}
