/* -----------------------------------------------------------------------
 * #FONTS
 * ----------------------------------------------------------------------- */

@font-face {
  font-family: "Geomanist";
  src:
    url("/assets/fonts/geomanist-regular.woff2") format("woff2"),
    url("/assets/fonts/geomanist-regular.woff") format("woff");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Geomanist";
  src:
    url("/assets/fonts/geomanist-italic.woff2") format("woff2"),
    url("/assets/fonts/geomanist-italic.woff") format("woff");
  font-display: optional;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Geomanist";
  src:
    url("/assets/fonts/geomanist-book.woff2") format("woff2"),
    url("/assets/fonts/geomanist-book.woff") format("woff");
  font-display: optional;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Geomanist";
  src:
    url("/assets/fonts/geomanist-bold.woff2") format("woff2"),
    url("/assets/fonts/geomanist-bold.woff") format("woff");
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}
