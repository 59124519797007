/* -----------------------------------------------------------------------
 * #BANNER
 * ----------------------------------------------------------------------- */

/**
 * @todo: Add description
 */

.c-banner {
  --banner-spacing: var(--spacing-xl);

  position: relative;
  padding-top: var(--banner-spacing);
  padding-bottom: var(--banner-spacing);
}

  .c-banner__label {
    position: absolute;
    bottom: 100%;
    right: 2rem;
    display: block;
    padding: 0.5rem 2rem 0;
    border-top-left-radius: 0.25em;
    border-top-right-radius: 0.25em;
    font: inherit;
    line-height: inherit;
    letter-spacing: 0.0625em;
    text-transform: uppercase;
  }



/**
 * @todo: Add description
 */

.c-banner--preface {

  & > .c-banner__inner {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      z-index: var(--layer-above);
      display: block;
      width: 34px;
      height: 222px;
      margin-top: var(--spacing-md);
      background: url("../images/arrow-down.svg") no-repeat;

      @media (--lg-down) {
        display: none;
      }
    }
  }
}
