/* -----------------------------------------------------------------------
 * #COLORS
 * ----------------------------------------------------------------------- */

/**
 * Global Colors
 *
 * A collection of project-wide color variables. You should prefer the HSL color
 * space over RGB as it is easier to read.
 *
 * Currently, colour variants are generated by hand. As soon as the new CSS
 * Color Specification 5 comes closer to a stable draft, the variants should be
 * generated using the provided color functions.
 *
 * @see {@link https://drafts.csswg.org/css-color-5/#relative-colors}
 * @see {@link https://github.com/w3c/csswg-drafts/issues/3187#issuecomment-499126198}
 *
 * @example css
 *   .c-button--primary {
 *     color: var(--color-text-inverse);
 *     background-color: var(--color-primary);
 *   }
 */

:root {

  /* Colors Scheme
   * --------------------------------------------------------------------- */

  --color-primary:           hsl(51 87% 63%);
  --color-primary-light:     hsl(51 87% 75%);
  --color-primary-dark:      hsl(51 87% 54%);
  --color-secondary:         hsl(206 100% 32%);
  --color-secondary-light:   hsl(206 100% 46%);
  --color-secondary-dark:    hsl(206 100% 18%);

  /* Neutral Colors
   * --------------------------------------------------------------------- */

  --color-white:             hsl(0 0% 100%);
  --color-black:             hsl(0 0% 0%);
  --color-neutral:           hsl(0 0% 46%);
  --color-neutral-light:     hsl(0 0% 75%);
  --color-neutral-lighter:   hsl(0 0% 96%);
  --color-neutral-dark:      hsl(0 0% 32%);
  --color-neutral-darker:    hsl(0 0% 4%);

  /* Text Colors
   * ---------------------------------------------------------------------- */

  --color-text:              var(--color-neutral-darker);
  --color-text-inverse:      var(--color-neutral-lighter);
  --color-text-muted:        var(--color-neutral);

  /* Background Colors
   * ---------------------------------------------------------------------- */

  --color-canvas:            hsl(0 0% 100%);
  --color-canvas-tint:       hsl(60 16% 94%);
  --color-canvas-inverse:    hsl(0 0% 18%);

  /* UI Colors
   * ---------------------------------------------------------------------- */

  --color-selection:         var(--color-primary-light);
  --color-outline:           var(--color-secondary-light);
  --color-border:            var(--color-neutral-light);
  --color-border-inverse:    var(--color-neutral-dark);
}
